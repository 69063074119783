import React from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { FaSadTear } from "react-icons/fa";
import i18next from "i18next";

const ErrorModal = (props) => {
  const { t } = useTranslation(["translation", "error"]);


  return (
    <Dialog
      visible={!!props.error}
      onCancel={props.onClear}
      onHide={props.onClear}
      closable={false}
      closeOnEscape={true}
      style={{ minWidth: "30rem" }}
    >
      <div className="flex flex-column align-items-center gap-3">
        <div
          className={`flex align-items-center justify-content-center border-round bg-red-400 border-circle p-3`}
          style={{ width: "5rem", height: "5rem" }}
        >
          <IconContext.Provider
            value={{
              color: "text-white",
              className: `text-3xl lg:text-5xl react-icons text-white`,
            }}
          >
            <FaSadTear />
          </IconContext.Provider>
        </div>
        <span className="font-bold text-lg text-center">{t("errorTitle")}</span>
        <span className="text-center">{i18next.t(props.error, { ns: "error" })}</span>
        <Button className="mt-5" onClick={props.onClear} label="Ok"></Button>
      </div>
    </Dialog>
  );
};

export default ErrorModal;
