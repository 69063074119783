import { useCallback, useState } from "react";
import { auth as firebaseAdmin } from "./../../config/firebase";

export const useAuth = () => {
  const [userCred, setUserCred] = useState(null);
  const [companyId, setCompanyId] = useState();
  const [companyLst, setCompanyLst] = useState(userCred && userCred.companyLst);

  const login = useCallback((userCred) => {
    setUserCred(userCred);
  }, []);

  const logout = useCallback(() => {
    firebaseAdmin.signOut();
  }, []);

  return {
    companyId,
    setCompanyId: useCallback((company) => {
      setCompanyId(company);
    }, []),
    companyLst,
    setCompanyLst: useCallback((company) => {
      setCompanyLst(company);
    }, []),
    userCred,
    login,
    logout,
  };
};
