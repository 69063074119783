import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  isLoadingUserData: false,
  userCred: null,
  companyLst: null,
  companyId: null,
  displayName: null,
  companyInfo: null,
  siteInfo: null,
  email: null,
  picture: null,
  login: (userCred) => {},
  logout: () => {},
  updateCompanyId: () => {},
});
